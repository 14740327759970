// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'rowNames', 'colNames', 'rows', 'cols' ]

  process(namesElement, countElement) {
    let names = namesElement.value
    if (names.length > 0) {
      countElement.value = names.split(',').length
      countElement.classList.add('disabled')
      //countElement.closest('.input-field').style.display = 'none'
    } else {
      countElement.value = null
      countElement.classList.remove('disabled')
      //countElement.closest('.input-field').style.display = ''
    }
  }

  connect() {
    console.log('connected SmartTablesCreate controller')
  }

  update() {
    console.log("updating")
    this.process(this.rowNamesTarget, this.rowsTarget)
    this.process(this.colNamesTarget, this.colsTarget)
  }
}

// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static values = { text: String }

  connect() {
    tippy(this.element, {content: this.textValue, interactive: true, maxWidth: '600px', allowHTML: true})
    this.element._tippy.popper.classList.add("tag-tooltip")
  }

}

// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "startDate", "endDate", "startTime", "endTime" ]

  connect() {
    if (localStorage.sensor_date_from != undefined){
      this.startDateTarget.value = localStorage.sensor_date_from;
      this.startTimeTarget.value = '00:00';
      this.startDateTarget.style.background = 'pink'
      this.startTimeTarget.style.background = 'pink'
    }
    if (localStorage.sensor_date_to != undefined){
      this.endDateTarget.value = localStorage.sensor_date_to;
      this.endTimeTarget.value = '00:00';
      this.endDateTarget.style.background = 'pink'
      this.endTimeTarget.style.background = 'pink'
    }
  }
}

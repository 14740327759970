// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "select", "chips" ]
  locationsIds = {}

  connect() {
    // this allows external calls to controller functions
    this.element['chipMultiselect'] = this

    let div = document.createElement("div")
    div.classList.add("no-autoinit")
    div.classList.add("chips")
    div.classList.add("chips-autocomplete")
    div.dataset.chipMultiselectTarget = 'chips'
    this.selectTarget.parentElement.append(div)
    let options = { autocompleteOptions: {
        data: this.locations(),
        limit: Infinity,
        minLength: 1
      },
      onChipAdd: this.add,
      onChipDelete: this.remove
    }
    let instance = M.Chips.init(this.chipsTarget, options)
    Array.from(this.selectTarget.selectedOptions).forEach(function(option){
      instance.addChip({tag: option.innerText})
    })
  }

  add(element, chip) {
    let chips = this
    let controller = chips.$el[0].closest('[data-controller=chip-multiselect]').chipMultiselect
    let locationName = chip.innerText.match(/(.*)\n*close$/)[1]
    let locationId = controller.locationIds[locationName]
    if (locationId == undefined) {
      chips.chipsData.forEach(function(e, index) {
        if(!(e.tag in controller.locations()))
          chips.deleteChip(index);
      })
    } else {
      controller.selectTarget.querySelector(`option[value="${locationId}"]`).selected = true
    }
  }

  remove(element, chip) {
    let controller = this.$el[0].closest('[data-controller=chip-multiselect]').chipMultiselect
    let locationName = chip.innerText.match(/(.*)\n*close$/)[1]
    let locationId = controller.locationIds[locationName]
    let option = controller.selectTarget.querySelector(`option[value="${locationId}"]`)
    if (option) option.selected = false
  }

  reset() {
    let count = this.chipsInstance().$chips.length
    for(let i = 0; i < count; i++){
      this.chipsInstance().deleteChip(0)
    }
  }

  locations() {
    this.locationIds = {}
    let that = this
    return [...this.selectTarget.options].reduce(function(acc, el){
      that.locationIds[el.innerHTML] = el.value
      acc[el.innerHTML] = null;
      return acc
    }, {})
  }

  chipsInstance() {
    return M.Chips.getInstance(this.chipsTarget)
  }

  updateLocations(){
    this.reset()
    this.chipsInstance().autocomplete.updateData(this.locations())
  }
}

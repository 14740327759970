// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static values = { channelId: String }

  connect() {
    console.log("cable messenger connected")
    var that = this
    App.cable.subscriptions.create({
      channel: "CableMessengerChannel",
      message_id: this.channelIdValue},
      {
        received(data) {
          that.element.innerText = data.text
          if(data.action == "reload") location.reload();
        }
      })
    if (App.cable2 != undefined) {
      App.cable2.subscriptions.create({
        channel: "CableMessengerChannel",
        message_id: this.channelIdValue},
        {
          received(data) {
            that.element.innerText = data.text
            if(data.action == "reload") location.reload();
          }
        })
    }
  }

}

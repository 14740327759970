// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    url: String,
    id: Number
  }

  async connect() {
    if (!this.element.innerHTML) {
      if (map.loadedPopups[this.idValue]) {
        this.element.innerHTML = map.loadedPopups[this.idValue]
        if (this.element.innerHTML == "<span></span>") this.element.style.minHeight = 'unset'
      } else {
        $(this.element).spin()
        let data;
        let res = await fetch(this.urlValue)
        data = await res.json()

        if (Object.values(data.wago_sensors).flat().length > 0) this.element.appendChild(this.wagoDataTable(data))
        if (Object.keys(data.dc_power_sources).length > 0) this.element.appendChild(this.dcDataTable(data))
        if (Object.keys(data.ats).length > 0) this.element.appendChild(this.atsDataTable(data))

        $(this.element).spin(false)

        //prevent infinite loop when detail is empty
        if (this.element.innerHTML == "") {
          this.element.style.minHeight = 'unset'
          this.element.appendChild(document.createElement('span'))
        }

        map.loadedPopups[this.idValue] = this.element.innerHTML
      }
      this.refreshContent()
    }
    this.observeSensorBadges()
    this.handleEditLock()
  }

  observeSensorBadges(){
    this.element.querySelectorAll('.sensor.badge').forEach(function(element){
      YAMT.Sensors.helperObserver.observe(element ,{ attributes: false, childList: true, subtree: false });
    })
  }

  handleEditLock() {
    if (!$("#edit-lock-button").hasClass("fa-unlock-alt")) {
      let orig_width = this.element.offsetWidth
      $(this.element).find('.hide-on-lock').css('display', 'none')
      if (orig_width != this.element.offsetWidth) this.refreshContent()
    } else {
      let orig_width = this.element.offsetWidth
      $(this.element).find('.hide-on-lock').css('display', 'auto')
      if (orig_width != this.element.offsetWidth) this.refreshContent()
    }
  }

  wagoDataTable(data) {
    let table = document.createElement('table')
    Object.entries(data.wago_sensors).forEach(function([key, sensors]){
      sensors.forEach(function(sensor_data){
        table.appendChild(this.createRow(key, sensor_data.sensor_helper))
      }, this)
    }, this)
    return table
  }

  dcDataTable(data) {
    let table = document.createElement('table')
    Object.entries(data.dc_power_sources).forEach(function([i,psu]){
      let row = document.createElement('tr')
      let col = document.createElement('td')
      col.colSpan = 2
      col.classList.add('text-bold')
      col.innerText = psu.name_to_display
      row.appendChild(col)
      table.appendChild(row)
      if (psu.status) table.appendChild(this.createRow("Status", psu.status_helper))
      if (psu.power) table.appendChild(this.createRow("Power", psu.power_helper))
      if (psu.battery_voltage) table.appendChild(this.createRow("Voltage", psu.battery_voltage_helper))
      if (psu.battery_capacity) table.appendChild(this.createRow("Capacity remaining", psu.battery_capacity_helper))
      if (psu.battery_time_remaining) table.appendChild(this.createRow("Time remaining", psu.battery_time_remaining_helper))
    }, this)
    return table
  }

  atsDataTable(data){
    let ats = data.ats
    let table = document.createElement('table')
    let row = document.createElement('tr')
    let col = document.createElement('td')
    col.colSpan = 2
    col.classList.add('text-bold')
    col.innerText = ats.name_to_display
    row.appendChild(col)
    table.appendChild(row)
    if (ats.mode) table.appendChild(this.createRow("Mode", ats.mode_helper))
    if (ats.engine_status) table.appendChild(this.createRow("Engine status", ats.engine_status_helper))
    if (ats.engine_rpm) table.appendChild(this.createRow("Engine RPM", ats.engine_rpm_helper))
    return table
  }

  createRow(first, second) {
    let row = document.createElement('tr')
    let col = document.createElement('td')
    col.innerHTML = first
    row.appendChild(col)
    col = document.createElement('td')
    col.innerHTML = second
    col.classList.add('right')
    row.appendChild(col)
    return row
  }

  refreshContent() {
    //necessary for the anchor to work correctly
    console.log("refreshing popup")
    map.leafletMap._popup.setContent(this.element.parentElement.innerHTML)
  }

}

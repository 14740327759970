// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static values = { skipAutoinit: Boolean }

  connect() {
    YAMT.Location.initMap();
    if (!this.skipAutoinitValue) this.update();
  }

  keypress(event) {
    if (event.keyCode == 13) {
      this.update();
      event.preventDefault();
      return false;
    }
  }

  update() {
    YAMT.Location.searchAddress();
  }
}

// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    this.inputTargets.forEach(function(element){
      element.value = localStorage[element.name]  
    })
  }

  clear() {
    this.inputTargets.forEach(function(element){
      localStorage.removeItem(element.name)
      element.value = undefined
    })
  }

  store(event) {
    localStorage[event.target.name] = event.target.value;
  }
}
